import React from 'react';

// Components
import { FormFieldCheckbox, FormFieldText } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import { request } from 'core/constants';
import Header from 'components/lib/Header';
import Button from 'components/lib/Button';

import PropertySelection from 'features/components/fieldsGroup/PropertySelection';
// import PropertyOwnerSelection from 'features/components/fieldsGroup/PropertyOwnerSelection';
import GuarantorSelection from 'features/components/fieldsGroup/GuarantorSelection';
import ClientSelection from 'features/components/fieldsGroup/ClientSelection';
import { rawNumber } from 'utils/utils';

import integrations from '../../';

export default class CreateSolicitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      guarantors: [],
      applicantList: [],
      applicantsSelected: [],
      property: [],
      isLoading: false,
      applicantValid: true,
      message: '',
      propertyTypeValid: false,
    };
  }

  async formatapplicantList() {
    //cria lista de pretendentes com as entidades selecionadas
    let { clients, guarantors } = this.state

    let applicants = []

    if (clients?.length > 0) {
      const clientRequests = clients.map(async (item) => {
        const client = (await request
          .get(`{company}/client/${item.id}`)).data

        if (client.cnpj) {
          applicants.push({
            applicant: { ...client, izeeApplicantType: 'INQUILINO-PJ' },
            key: client.email + client.id,
            applicantType: 'OUTROS',
          });
        } else {
          applicants.push({
            applicant: client,
            key: client.email + client.id,
            applicantType: 'INQUILINO',
          });
          if (client.spouse_email) {
            applicants.push({
              applicant: client,
              key: client.spouse_email + client.id,
              applicantType: 'CONJUGE_INQUILINO',
            });
          }
        }
      })

      await Promise.all(clientRequests)
    }

    if (guarantors?.length > 0) {
      const guarantorRequests = guarantors.map(async (item) => {
        const guarantor = (await request.get(`{company}/guarantor/${item.id}`)).data

        if (guarantor.cnpj) {
          applicants.push({
            applicant: { ...guarantor, izeeApplicantType: 'FIADOR-PJ' },
            key: guarantor.email + guarantor.id,
            applicantType: 'OUTROS',
          });
        } else {
          applicants.push({
            applicant: guarantor,
            key: guarantor.email + guarantor.id,
            applicantType: 'FIADOR',
          });
          if (guarantor.spouse_email) {
            applicants.push({
              applicant: guarantor,
              key: guarantor.spouse_email + guarantor.id,
              applicantType: 'CONJUGE_FIADOR',
            });
          }
        }
      });

      await Promise.all(guarantorRequests)
    }
    this.setState({ applicantList: applicants, applicantsSelected: applicants });
  }

  formatName(item) {
    switch (item.applicantType) {
      case 'OUTROS':
        return item.applicant.corporate_name + ' - Empresa';

      case 'INQUILINO':
        return item.applicant.name + ' - Cliente';

      case 'FIADOR':
        return item.applicant.name + ' - Fiador';

      case 'CONJUGE_INQUILINO':
        return item.applicant.spouse_name + ' - Conjuge do(a) cliente';

      case 'CONJUGE_FIADOR':
        return item.applicant.spouse_name + ' - Conjuge do Fiador';
    }
  }

  handleFormField(rent_value) {
    this.setState({ rent_value: rent_value });
  }

  selectedClientsHandler(clients) {
    this.setState({ clients }, () => {
      this.formatapplicantList();
    });
  }

  selectedGuarantorsHandler(guarantors) {
    this.setState({ guarantors }, () => {
      this.formatapplicantList();
    });
  }

  selectedPropertyHandler(property) {
    request
      .get(`{company}/property/${property.id}`)
      .then((response) => {
        const p = response.data

        this.setState({
          propertyTypeValid: p?.type?.code ? true : false,
          property: p,
          propertyOwner: p.property_owners?.[0] || [],
        })
      })
      .catch((error) => { console.log(error) })

  }

  handleFieldChanges() {
    this.setState({ applicantValid: true });
  }

  render() {
    const {
      applicantList,
      applicantsSelected,
      isLoading,
      message,
      clients,
      guarantors,
      property,
      applicantValid,
      propertyTypeValid,
    } = this.state;

    const insufficientCredits =
      this.props.creditAnalysisTickets - applicantsSelected.length < 0;

    return (
      <React.Fragment>
        <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
          Selecione os pretendentes para criar a Análise
        </Header>
        <Grid>
          <Grid.Col>
            <div className="text-right">
              <p>Creditos Consumidos: {applicantsSelected.length}</p>
            </div>
            {insufficientCredits && (
              <div className="mb-4">
                <p>
                  <b>Quantidade de créditos insuficiente.</b>
                </p>
              </div>
            )}
            {!applicantValid && (
              <div className="mb-4">
                <p>
                  Para criar a solicitação informe os dados do pretendente
                  selecionado:
                </p>
                <ul>
                  <li>
                    <b>- Nome/Razão Social</b>
                  </li>
                  <li>
                    <b>- CPF/CNPJ</b>
                  </li>
                  <li>
                    <b>- Renda</b>
                  </li>
                  <li>
                    <b>- Fonte de Renda</b>
                  </li>
                </ul>
              </div>
            )}
            {!propertyTypeValid && property.id && (
              <div className="mb-4">
                <ul>
                  <li>
                    <b>- Para criar a solicitação informe o tipo do Imóvel</b>
                  </li>
                </ul>
              </div>
            )}

            {isLoading ? (
              <div>
                <div style={{ height: '250px', marginTop: 100 }}>
                  <div
                    style={{ margin: '0 auto', padding: '45px 60px' }}
                    className="empty-state-spinner"
                  />
                  <p className="text-center mt-2">
                    {' '}
                    <strong>{message}</strong>
                  </p>
                </div>
              </div>
            ) : (
              <div className="create-solicitation-fc">
                <PropertySelection
                  {...this.props}
                  onSelect={this.selectedPropertyHandler.bind(this)}
                  isValid={true}
                  // errorMessage="Informe pelo menos um imóvel"
                  // showAddition={false}
                  handleFieldChanges={this.handleFieldChanges.bind(this)}
                />

                <FormFieldText
                  label="Valor do Aluguel"
                  name="rent_value"
                  mask={['number']}
                  onChange={this.handleFormField.bind(this)}
                />

                <ClientSelection
                  {...this.props}
                  onSelect={this.selectedClientsHandler.bind(this)}
                  isValid={true}
                  clients={clients}
                  handleFieldChanges={this.handleFieldChanges.bind(this)}
                />

                <GuarantorSelection
                  {...this.props}
                  onSelect={this.selectedGuarantorsHandler.bind(this)}
                  isValid={true}
                  // showAddition={false}
                  guarantors={guarantors}
                  handleFieldChanges={this.handleFieldChanges.bind(this)}
                />
                {applicantList.map((item) => {
                  const isChecked = applicantsSelected.filter(
                    (applicant) => applicant.key === item.key
                  ).length;
                  return (
                    <FormFieldCheckbox
                      key={item.key}
                      name={this.formatName(item)}
                      isDisabled={this.props.formIsWorking}
                      // defaultChecked={isChecked}
                      checked={isChecked}
                      // onChange={this.handleCheckboxChanges.bind(this, item)}
                      onClick={() => this.handleCheckboxChanges(item, isChecked)}
                      description={this.formatName(item)}
                    />
                  );
                })}
              </div>
            )}

            <div className="text-right mt-3">
              <Button
                style={{ marginRight: '5px' }}
                isSmall
                disabled={
                  isLoading ||
                  !applicantsSelected.length ||
                  !applicantValid ||
                  insufficientCredits ||
                  !propertyTypeValid
                }
                onClick={() => this.submit()}
              >
                Criar Solicitação
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  validateapplicants() {
    let applicants = this.state.applicantsSelected;
    let isValid = true;

    applicants.map((item) => {
      if (
        item.applicantType == 'OUTROS' &&
        (!item.applicant.corporate_name || !item.applicant.cnpj)
      ) {
        this.setState({ applicantValid: false });
        isValid = false;
        return;
      }

      if (
        (item.applicantType == 'INQUILINO' || item.applicantType == 'FIADOR') &&
        (!item.applicant.name ||
          !item.applicant.cpf ||
          !item.applicant.income_source)
      ) {
        this.setState({ applicantValid: false });
        isValid = false;
        return;
      }

      if (
        (item.applicantType == 'CONJUGE_INQUILINO' ||
          item.applicantType == 'CONJUGE_FIADOR') &&
        (!item.applicant.spouse_name ||
          !item.applicant.spouse_cpf ||
          !item.applicant.spouse_income_source)
      ) {
        this.setState({ applicantValid: false });
        isValid = false;
        return;
      }
    });

    this.setState({ applicantValid: isValid });
    return isValid;
  }

  submit() {
    let isValid = this.validateapplicants();

    if (!isValid) {
      return;
    }

    let { companyId, companyDocument } = this.props;
    let { property, propertyOwner, applicantsSelected } = this.state;

    property.rent_value = rawNumber(this.state.rent_value)

    let applicants = JSON.parse(JSON.stringify(applicantsSelected));
    let index = 0;
    let applicantToCreate = applicants.splice(index, 1)[index];
    let applicantsToAdd = applicants;

    this.setState({ isLoading: true, message: 'Criando Solicitação...' });
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'CREATE',
          companyId,
          companyDocument,
          applicant: applicantToCreate.applicant,
          applicantType: applicantToCreate.applicantType,
          property,
          propertyOwner,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao criar a solicitação, retorna o erro
          this.setState({ isLoading: false });
          this.props.onSuccess(success, data, false);
          return;
        }

        let successCreate = success;
        let dataCreate = data;
        let solicitationId = data.app.response.data.id;

        if (applicantsToAdd.length > 0) {
          //depois de criar a solicitação adiciona os pretendentes se existir
          this.setState({ message: 'Adicionando pretendentes...' });
          applicantsToAdd.map((applicant, index) => {
            integrations
              .apps({
                appName: 'fichacerta',
                companyId,
                payload: {
                  action: 'APPLICANT_ADD',
                  companyId,
                  companyDocument,
                  solicitationId: solicitationId,
                  property: property,
                  propertyOwner: propertyOwner,
                  applicant: applicant.applicant,
                  applicantType: applicant.applicantType,
                },
              })
              .then(({ success, data }) => {
                if (!data.app.response.success) {
                  // se deu erro ao adicionar o pretendente, retorna o erro
                  this.setState({ isLoading: false });
                  this.props.onSuccess(success, data, false);
                  return;
                }
                // this.setState({ message: 'Pretendente ' + applicant.applicant.name + ' adicionado' });

                if (index + 1 === applicantsToAdd.length) {
                  // se foi o ultimo pretendente adicionado executa o laudo
                  this.executeReport(
                    companyId,
                    solicitationId,
                    successCreate,
                    dataCreate
                  ); // executa o laudo
                }
              })
              .catch((error) => {
                console.log('Fichacerta create error', error);
              });
          });
        } else {
          this.executeReport(companyId, solicitationId, successCreate, dataCreate); // executa o laudo
        }
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }

  handleCheckboxChanges(item, value) {
    let list = this.state.applicantsSelected;
    if (!value) {
      if (list.filter((listItem) => listItem.key === item.key).length) return;
      list.push(item);
    } else {
      list = list.filter((listItem) => listItem.key !== item.key);
    }
    this.setState({ applicantValid: true });
    this.setState({ applicantsSelected: list });
  }

  executeReport(companyId, solicitationId, successCreate, dataCreate) {
    this.setState({ message: 'Solicitando laudo...' });
    integrations
      .apps({
        // executa o laudo
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_EXECUTE',
          companyId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro na criação do laudo, retorna o erro
          this.setState({ isLoading: false });
          this.props.onSuccess(success, data, false);
          return;
        }

        this.setState({ applicantsSelected: [] });
        this.setState({ isLoading: false, message: '' });
        this.props.onSuccess(successCreate, dataCreate, false);
        return { success, data };
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }
}
