import React from 'react'

import ReactToPrint from 'react-to-print'
import { connect } from 'react-redux'
import moment from 'moment'

import * as commons from 'utils/UtilityStore/actions'

import { request } from 'core/constants'
import { goodObject, rawNumber } from 'utils/utils'

import { ga } from '../../services/firebase'

import { cnpjValidation } from 'utils/cnpjValidation'
import { cpfValidation } from 'utils/cpfValidation'
import { copyToClipboard } from 'utils/utils'

import PersonalDataClient from 'features/components/fieldsGroup/PersonalDataClient'
import FullTradingAddress from 'features/components/fieldsGroup/FullTradingAddress'
import PersonalDataSpouse from 'features/components/fieldsGroup/PersonalDataSpouse'
import formCreator from 'features/components/formCreator'
import FullAddress from 'features/components/fieldsGroup/FullAddress'

import BodyContainer from 'components/lib/BodyContainer'
import Modal from 'components/lib/RegisterFormModal'
import ConfirmationModal from 'components/lib/Modal'
import SmallModal from 'components/lib/Modal'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'

import RequestByEmail from 'components/Documents/components/Email'
import ConfirmationMessage from 'components/ConfirmationMessage'
import { MARITAL_STATUS } from 'components/constants'
import DocumentComponent from 'components/Documents'
import withModal from 'components/HOCs/withModal'
import Grid from 'components/lib/Grid'

import PersonalData from 'components/Proposal/components/PersonalData'
import DataSpouse from 'components/Proposal/components/PersonalDataSpouse'
import Address from 'components/Proposal/components/Address'
import UserName from 'components/Proposal/components/UserName'

import * as actions from './actions'

import ConfirmExcludeModal from 'components/ConfirmExcludeModal'

class ClientDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showExcludeModal: false,
      companyInfo: {
        companyName: '',
        themeColor: '',
      },
      modalIsOpen: true,
      smallModalIsOpen: false,
      confirmationModal: false,
      success: false,
      message: '',
      selected: 0,
      userId: '',
      companyId: '',
      isFirstTime: true,
      formIsWorking: false,
      formIsInvalid: false,
      fieldErrors: {},
      defaultValue: {
        name: '',
        email: '',
        phone: '',
        cpf: '',
        identity_type: '',
        identity: '',
        identity_issuing_body: '',
        identity_expedition_date: '',
        birth_date: '',
        nationality: '',
        gender: 'M',
        mother_name: '',
        marital_status: '',
        marital_status_id: '',
        occupation: '',
        income: '0,00',
        income_source: '',
        income_source_id: '',
        is_student: '',
        zip_code: '',
        address: '',
        number: '',
        additional_address: '',
        state: '',
        city: '',
        neighborhood: '',
        spouse_name: '',
        spouse_email: '',
        spouse_phone: '',
        spouse_cpf: '',
        spouse_identity_type: '',
        spouse_identity: '',
        spouse_identity_issuing_body: '',
        spouse_identity_expedition_date: '',
        spouse_birth_date: '',
        spouse_nationality: '',
        spouse_occupation: '',
        spouse_status: '',
        estimated_rent_value: 1,
        spouse_income: '0,00',
        spouse_income_source: '',
        spouse_income_source_id: '',
        cnpj: '',
        corporate_name: '',
        trading_name: '',
        municipal_enrollment: '',
        state_enrollment: '',
        typeJuridical: false,
        trading_address_zip_code: '',
        trading_address_address: '',
        trading_address_number: '',
        trading_address_additional_address: '',
        trading_address_state: '',
        trading_address_city: '',
        trading_address_neighborhood: '',
      },
    }
    this.callBack = this.callBack.bind(this)
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.clientId)
    const companyInfo = {
      companyName: this.props.activeCompany.name,
      themeColor: this.props.companyMeta.themeColor,
    }

    this.setState({ companyInfo })
  }

  componentWillUnmount() {
    this.props.clear()
  }

  componentDidUpdate() {
    const { isFirstTime } = this.state

    if (Object.keys(this.props.current).length > 0) {
      if (!isFirstTime) return
      let fields = {}
      const current = this.props.current
      const { address } = current

      let trading_address = {}

      if (current.trading_address) {
        trading_address = {
          trading_address_zip_code: current.trading_address.zip_code || '',
          trading_address_address: current.trading_address.address || '',
          trading_address_number: parseInt(current.trading_address.number) || '',
          trading_address_additional_address:
            current.trading_address.additional_address || '',
          trading_address_state: current.trading_address.state || '',
          trading_address_city: current.trading_address.city || '',
          trading_address_neighborhood: current.trading_address.neighborhood || '',
        }
      }

      fields = { ...current, ...address, ...trading_address }

      const values = (({
        name,
        email,
        phone,
        cpf,
        identity_type,
        identity,
        identity_issuing_body,
        identity_expedition_date,
        birth_date,
        nationality,
        gender,
        mother_name,
        marital_status,
        marital_status_id,
        occupation,
        estimated_rent_value,
        income,
        income_source,
        income_source_id,
        is_student,
        zip_code,
        address,
        number,
        additional_address,
        state,
        city,
        neighborhood,
        spouse_birth_date,
        spouse_cpf,
        spouse_email,
        spouse_identity,
        spouse_identity_expedition_date,
        spouse_identity_issuing_body,
        spouse_identity_type,
        spouse_is_student,
        spouse_name,
        spouse_nationality,
        spouse_occupation,
        spouse_phone,
        spouse_income,
        spouse_income_source,
        spouse_income_source_id,
        cnpj,
        corporate_name,
        trading_name,
        municipal_enrollment,
        state_enrollment,
        trading_address_zip_code,
        trading_address_address,
        trading_address_number,
        trading_address_additional_address,
        trading_address_state,
        trading_address_city,
        trading_address_neighborhood,
      }) => ({
        name,
        email,
        phone,
        cpf,
        identity_type,
        identity,
        identity_issuing_body,
        identity_expedition_date,
        birth_date,
        nationality,
        gender,
        mother_name,
        marital_status,
        marital_status_id,
        occupation,
        income,
        income_source,
        income_source_id,
        estimated_rent_value,
        is_student,
        zip_code,
        address,
        number,
        additional_address,
        state,
        city,
        neighborhood,
        spouse_birth_date,
        spouse_cpf,
        spouse_email,
        spouse_identity,
        spouse_identity_expedition_date,
        spouse_identity_issuing_body,
        spouse_identity_type,
        spouse_is_student,
        spouse_name,
        spouse_nationality,
        spouse_occupation,
        spouse_phone,
        spouse_income,
        spouse_income_source,
        spouse_income_source_id,
        cnpj,
        corporate_name,
        trading_name,
        municipal_enrollment,
        state_enrollment,
        trading_address_zip_code,
        trading_address_address,
        trading_address_number,
        trading_address_additional_address,
        trading_address_state,
        trading_address_city,
        trading_address_neighborhood,
      }))(fields)

      values.typeJuridical = !!fields.cnpj

      Object.keys(values).forEach((key) => {
        if (values[key] === null || values[key] === undefined) {
          values[key] = ''
          if (key == 'income') {
            values[key] = '0,00'
          }
        }
      })

      return this.setState({
        fields: values,
        defaultValue: values,
        isFirstTime: !isFirstTime,
        userId: this.props.current.id,
        companyId: this.props.current.company.id,
      })
    }

    return null
  }

  forward() {
    let { selected } = this.state
    const isValid = this.validatePersonalData(selected)

    let resumo = 0

    if (selected == resumo) {
      this.setState({ selected: ++selected })
    } else {
      if (isValid) {
        this.updateClient(selected)
        if (selected == 4) return
        this.setState({ selected: ++selected })
      }
    }
  }

  behind() {
    let { selected } = this.state

    if (selected == 0) return null
    this.setState({ selected: --selected })
  }

  callBack(data) {
    let section = --data // TODO ?

    let selected = this.state.selected
    const isValid = this.validatePersonalData(selected)

    if (!isValid) {
      this.setState({ formIsInvalid: selected })
    } else {
      this.updateClient()
      this.setState({
        selected: section,
        formIsInvalid: false,
      })
    }
  }

  close() {
    // this.props.history.goBack();
    const {
      location: { pathname },
    } = this.props.history
    const newPathName = pathname.split('/')

    newPathName.pop()
    const path = newPathName.join('/')

    if (this.props.location.state) {
      if (this.props.location.state.from === 'proposal') {
        return this.props.history.goBack()
      }
    } else {
      return this.props.history.push(path)
    }
  }

  validatePersonalData = (step) => {
    if (step === 0) return true
    const {
      name,
      email,
      phone,
      cpf,
      birth_date,
      nationality,
      identity_issuing_body,
      occupation,
      identity,
      identity_type,
      income,
      income_source_id,
      cnpj,
      corporate_name,
      typeJuridical,
      marital_status_id,
      zip_code,
      address,
      number,
      state,
      city,
      neighborhood,
      trading_address_zip_code,
      trading_address_address,
      trading_address_neighborhood,
      trading_address_number,
      trading_address_city,
      trading_address_state,
      spouse_status,
      spouse_name,
      spouse_email,
      spouse_phone,
      spouse_cpf,
      spouse_birth_date,
      spouse_nationality,
      spouse_identity_issuing_body,
      spouse_occupation,
      spouse_identity,
      spouse_identity_type,
      spouse_income,
      spouse_income_source_id,
    } = this.state.defaultValue

    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

    const fieldErrors = {}

    let isValid = true

    if (typeJuridical) {
      if (cnpj && cnpj !== '') {
        const cnpjIsValid = cnpjValidation(cnpj)

        if (!cnpjIsValid) {
          fieldErrors['cnpj'] = ['CNPJ inválido', true]
          isValid = false
        } else {
          delete fieldErrors['cnpj']
        }
      } else {
        fieldErrors['cnpj'] = ['Campo CNPJ é obrigatório', true]
        isValid = false
      }
      if (!corporate_name || corporate_name === '') {
        fieldErrors['corporate_name'] = ['Campo Razão Social é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['corporate_name']
      }
    }

    if (!name || name === '') {
      fieldErrors['name'] = ['Campo Nome é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['name']
    }

    if (!birth_date || birth_date === '') {
      fieldErrors['birth_date'] = ['Campo Data de nascimento é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['birth_date']
    }

    if (!nationality || nationality === '') {
      fieldErrors['nationality'] = ['Campo Nacionalidade é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['nationality']
    }

    if (!identity_type || identity_type === '') {
      fieldErrors['identity_type'] = ['Campo Documento é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['identity_type']
    }

    if (!identity || identity === '') {
      fieldErrors['identity'] = ['Campo Número do documento é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['identity']
    }

    if (!identity_issuing_body || identity_issuing_body === '') {
      fieldErrors['identity_issuing_body'] = [
        'Campo Órgão emissor / UF é obrigatório',
        true,
      ]
      isValid = false
    } else {
      delete fieldErrors['identity_issuing_body']
    }

    if (!occupation || occupation === '') {
      fieldErrors['occupation'] = ['Campo Ocupação é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['occupation']
    }

    if (!emailRegex.test(email)) {
      fieldErrors['email'] = ['Email inválido', true]
      isValid = false
    } else {
      delete fieldErrors['email']
    }

    if (phone && phone !== '') {
      const plainPhone = phone.replace(/\D/g, '')
      const phoneLength = plainPhone.length

      if (phoneLength < 10) {
        fieldErrors['phone'] = ['Telefone inválido', true]
        isValid = false
      } else {
        delete fieldErrors['phone']
      }
    } else {
      fieldErrors['phone'] = ['Campo Telefone é obrigatório', true]
      isValid = false
    }

    if (!marital_status_id || marital_status_id === '') {
      fieldErrors['marital_status_id'] = ['Campo Estado civil é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['marital_status_id']
    }

    if (
      (!income || income === '' || income == '0,00') &&
      income_source_id != '1' &&
      income_source_id != ''
    ) {
      fieldErrors['income'] = ['Campo Renda é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['income']
    }

    if (!income_source_id || income_source_id === '') {
      fieldErrors['income_source_id'] = ['Campo Fonte de renda é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['income_source_id']
    }

    if (cpf && cpf !== '') {
      const plainCPF = cpf.replace(/\D/g, '')
      const cpfIsValid = cpfValidation(plainCPF)

      if (!cpfIsValid) {
        fieldErrors['cpf'] = ['CPF inválido', true]
        isValid = false
      } else {
        delete fieldErrors['cpf']
      }
    } else {
      fieldErrors['cpf'] = ['Campo CPF é obrigatório', true]
      isValid = false
    }

    // valida apenas no passo 2
    if (this.state.selected == 2) {
      if (!zip_code || zip_code === '') {
        fieldErrors['zip_code'] = ['Campo CEP é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['zip_code']
      }

      if (!address || address === '') {
        fieldErrors['address'] = ['Campo Logradouro é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['address']
      }

      if (!number || number === '') {
        fieldErrors['number'] = ['Campo Número é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['number']
      }

      if (!state || state === '') {
        fieldErrors['state'] = ['Campo Estado é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['state']
      }

      if (!city || city === '') {
        fieldErrors['city'] = ['Campo Cidade é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['city']
      }

      if (!neighborhood || neighborhood === '') {
        fieldErrors['neighborhood'] = ['Campo Bairro é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['neighborhood']
      }
      if (typeJuridical) {
        if (!trading_address_zip_code || trading_address_zip_code === '') {
          fieldErrors['trading_address_zip_code'] = ['Campo CEP é obrigatório', true]
          isValid = false
        } else {
          delete fieldErrors['trading_address_zip_code']
        }

        if (!trading_address_address || trading_address_address === '') {
          fieldErrors['trading_address_address'] = [
            'Campo Logradouro é obrigatório',
            true,
          ]
          isValid = false
        } else {
          delete fieldErrors['trading_address_address']
        }

        if (!trading_address_number || trading_address_number === '') {
          fieldErrors['trading_address_number'] = [
            'Campo Número é obrigatório',
            true,
          ]
          isValid = false
        } else {
          delete fieldErrors['trading_address_number']
        }

        if (!trading_address_state || trading_address_state === '') {
          fieldErrors['trading_address_state'] = ['Campo Estado é obrigatório', true]
          isValid = false
        } else {
          delete fieldErrors['trading_address_state']
        }

        if (!trading_address_city || trading_address_city === '') {
          fieldErrors['trading_address_city'] = ['Campo Cidade é obrigatório', true]
          isValid = false
        } else {
          delete fieldErrors['trading_address_city']
        }

        if (!trading_address_neighborhood || trading_address_neighborhood === '') {
          fieldErrors['trading_address_neighborhood'] = [
            'Campo Bairro é obrigatório',
            true,
          ]
          isValid = false
        } else {
          delete fieldErrors['trading_address_neighborhood']
        }
      }
    }

    // Verifica cônjuge
    if (spouse_status && this.state.selected === 3) {
      if (!spouse_name || spouse_name === '') {
        fieldErrors['spouse_name'] = ['Campo Nome é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_name']
      }

      if (!spouse_birth_date || spouse_birth_date === '') {
        fieldErrors['spouse_birth_date'] = [
          'Campo Data de nascimento é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_birth_date']
      }

      if (!spouse_nationality || spouse_nationality === '') {
        fieldErrors['spouse_nationality'] = [
          'Campo Nacionalidade é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_nationality']
      }

      if (!spouse_identity_type || spouse_identity_type === '') {
        fieldErrors['spouse_identity_type'] = ['Campo Documento é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_identity_type']
      }

      if (!spouse_identity || spouse_identity === '') {
        fieldErrors['spouse_identity'] = [
          'Campo Número do documento é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_identity']
      }

      if (!spouse_identity_issuing_body || spouse_identity_issuing_body === '') {
        fieldErrors['spouse_identity_issuing_body'] = [
          'Campo Órgão emissor / UF é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_identity_issuing_body']
      }

      if (!spouse_occupation || spouse_occupation === '') {
        fieldErrors['spouse_occupation'] = ['Campo Ocupação é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_occupation']
      }

      if (!emailRegex.test(spouse_email)) {
        fieldErrors['spouse_email'] = ['Email inválido', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_email']
      }

      if (spouse_phone && spouse_phone !== '') {
        const plainPhone = spouse_phone.replace(/\D/g, '')
        const phoneLength = plainPhone.length

        if (phoneLength < 10) {
          fieldErrors['spouse_phone'] = ['Telefone inválido', true]
          isValid = false
        } else {
          delete fieldErrors['spouse_phone']
        }
      } else {
        fieldErrors['spouse_phone'] = ['Campo Telefone é obrigatório', true]
        isValid = false
      }

      if (
        (!spouse_income || spouse_income === '' || spouse_income == '0,00') &&
        spouse_income_source_id != '1' &&
        spouse_income_source_id != ''
      ) {
        fieldErrors['spouse_income'] = ['Campo Renda é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_income']
      }

      if (!spouse_income_source_id || spouse_income_source_id === '') {
        fieldErrors['spouse_income_source_id'] = [
          'Campo Fonte de renda é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_income_source_id']
      }

      if (spouse_cpf && spouse_cpf !== '') {
        const plainCPF = spouse_cpf.replace(/\D/g, '')
        const cpfIsValid = cpfValidation(plainCPF)

        if (!cpfIsValid) {
          fieldErrors['spouse_cpf'] = ['CPF inválido', true]
          isValid = false
        } else {
          delete fieldErrors['spouse_cpf']
        }
      } else {
        fieldErrors['spouse_cpf'] = ['Campo CPF é obrigatório', true]
        isValid = false
      }
    } else {
      delete fieldErrors['spouse_name']
      delete fieldErrors['spouse_birth_date']
      delete fieldErrors['spouse_nationality']
      delete fieldErrors['spouse_identity_type']
      delete fieldErrors['spouse_identity']
      delete fieldErrors['spouse_identity_issuing_body']
      delete fieldErrors['spouse_occupation']
      delete fieldErrors['spouse_email']
      delete fieldErrors['spouse_phone']
      delete fieldErrors['spouse_income']
      delete fieldErrors['spouse_income_source_id']
      delete fieldErrors['spouse_cpf']
    }

    this.setState({ fieldErrors })

    return isValid
  }

  async updateClient(step) {
    const { defaultValue } = this.state

    if (step === 4) {
      this.setState({ formIsWorking: true })
    } // TODO was 3 changed to 4

    const requestFields = { ...defaultValue }

    const address = {
      additional_address: requestFields['additional_address'],
      address: requestFields['address'],
      city: requestFields['city'],
      neighborhood: requestFields['neighborhood'],
      number: requestFields['number'],
      state: requestFields['state'],
      zip_code: requestFields['zip_code'],
    }

    delete requestFields.additional_address
    delete requestFields.city
    delete requestFields.neighborhood
    delete requestFields.number
    delete requestFields.state
    delete requestFields.zip_code

    requestFields['address'] = address

    const trading_address = {
      additional_address: requestFields['trading_address_additional_address'],
      address: requestFields['trading_address_address'],
      city: requestFields['trading_address_city'],
      neighborhood: requestFields['trading_address_neighborhood'],
      number: requestFields['trading_address_number'],
      state: requestFields['trading_address_state'],
      zip_code: requestFields['trading_address_zip_code'],
    }

    delete requestFields.trading_address_additional_address
    delete requestFields.trading_address_address
    delete requestFields.trading_address_city
    delete requestFields.trading_address_neighborhood
    delete requestFields.trading_address_number
    delete requestFields.trading_address_state
    delete requestFields.trading_address_zip_code

    requestFields['trading_address'] = trading_address

    if (requestFields.marital_status) {
      requestFields['marital_status_id'] = requestFields.marital_status.id
    }

    let goodFields = goodObject(requestFields, {
      income: {
        path: 'income',
        format: (value) => rawNumber(value),
      },
      spouse_income: {
        path: 'spouse_income',
        format: (value) => rawNumber(value),
      },
      identity_expedition_date: {
        path: 'identity_expedition_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      birth_date: {
        path: 'birth_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      spouse_identity_expedition_date: {
        path: 'spouse_identity_expedition_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      spouse_birth_date: {
        path: 'spouse_birth_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      estimated_rent_value: {
        path: 'estimated_rent_value',
        format: (value) => rawNumber(value),
      },
    })

    try {
      await request.put(
        `/company/${this.props.current.company.id}/client/${this.props.current.id}`,
        goodFields,
      )

      if (step === 4) {
        this.setState({
          formIsWorking: false,
          confirmationModal: true,
          success: true,
          message: 'Edição realizada com sucesso!',
        })
        setTimeout(() => {
          this.setState(
            {
              confirmationModal: false,
              modalIsOpen: false,
            },
            () => {
              const gaPayload = {
                company_id: this.props.activeCompany.id,
                company_name: this.props.activeCompany.name,
              }

              ga.logEvent('update_client', gaPayload)
              this.close()
            },
          )
        }, 2500)
      }
    } catch (error) {
      if (step === 4) {
        const { errors } = error
        const message = errors[0].message

        this.setState({
          formIsWorking: false,
          confirmationModal: true,
          success: false,
          message: message,
        })
        setTimeout(() => {
          this.setState({
            confirmationModal: false,
          })
        }, 2500)
      }
    }
  }

  handleChange(name, value) {
    const { defaultValue, selected } = this.state
    const updatedFields = { ...defaultValue }

    if (name === 'typeJuridical') {
      updatedFields['typeJuridical'] = value
      if (value == false) {
        updatedFields['cnpj'] = ''
        updatedFields['corporate_name'] = ''
        updatedFields['trading_name'] = ''
        updatedFields['municipal_enrollment'] = ''
        updatedFields['state_enrollment'] = ''
        updatedFields['trading_address_zip_code'] = ''
        updatedFields['trading_address_neighborhood'] = ''
        updatedFields['trading_address_city'] = ''
        updatedFields['trading_address_address'] = ''
        updatedFields['trading_address_state'] = ''
        updatedFields['trading_address_number'] = ''
        updatedFields['trading_address_additional_address'] = ''
      }

      this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
      this.validatePersonalData(selected)

      return
    }

    if (name === 'spouse_status') {
      updatedFields['spouse_status'] = value
      if (value == false) {
        updatedFields['spouse_name'] = ''
        updatedFields['spouse_cpf'] = ''
        updatedFields['spouse_birth_date'] = ''
        updatedFields['spouse_email'] = ''
        updatedFields['spouse_identity'] = ''
        updatedFields['spouse_identity_issuing_body'] = ''
        updatedFields['spouse_identity_type'] = ''
        updatedFields['spouse_identity_expedition_date'] = ''
        updatedFields['spouse_nationality'] = ''
        updatedFields['spouse_occupation'] = ''
        updatedFields['spouse_phone'] = ''
        updatedFields['spouse_income_source_id'] = ''
        updatedFields['spouse_income'] = ''
      }

      this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
      this.validatePersonalData(selected)

      return
    }

    if (name === 'fullAddress') {
      updatedFields['zip_code'] = value['cep'] || ''
      updatedFields['neighborhood'] = value['bairro'] || ''
      updatedFields['city'] = value['localidade'] || ''
      updatedFields['address'] = value['logradouro'] || ''
      updatedFields['state'] = value['uf'] || ''

      this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
      this.validatePersonalData(selected)

      return
    }

    if (name === 'trading_address') {
      updatedFields['trading_address_zip_code'] = value['cep'] || ''
      updatedFields['trading_address_neighborhood'] = value['bairro'] || ''
      updatedFields['trading_address_city'] = value['localidade'] || ''
      updatedFields['trading_address_address'] = value['logradouro'] || ''
      updatedFields['trading_address_state'] = value['uf'] || ''

      this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
      this.validatePersonalData(selected)

      return
    }

    if (name === 'marital_status_id' && value) {
      const marital = MARITAL_STATUS.find((ms) => ms.id === parseInt(value, 10))

      if (marital) {
        updatedFields['marital_status'] = marital
      }
    }

    updatedFields[name] = value

    this.setState({
      fields: updatedFields,
      defaultValue: updatedFields,
    })
    this.validatePersonalData(selected)

    return
  }

  render() {
    const formConfig = {
      selected: this.state.selected,
      sections: [
        {
          buttonTitle: 'Avançar',
          formTitle: 'Editar cliente',
          progressBar: true,
          progressTitle: 'Resumo',
          secondButtonTitle: 'Solicitar Edição',
          sectionNumber: '1',
          sectionTitle: 'Resumo do(a) cliente',
        },
        {
          formTitle: 'Editar cliente',
          buttonTitle: 'Avançar',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '2',
          sectionTitle: 'Dados do(a) cliente',
          progressTitle: 'Dados',
        },
        {
          formTitle: 'Editar cliente',
          buttonTitle: 'Avançar',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '3',
          sectionTitle: 'Endereço do(a) cliente',
          progressTitle: 'Endereço',
        },
        {
          formTitle: 'Editar cliente',
          buttonTitle: 'Avançar',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '4',
          sectionTitle: 'Dados do(a) Cônjuge',
          progressTitle: 'Cônjuge',
        },
        {
          formTitle: 'Editar cliente',
          buttonTitle: 'Concluir',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '5',
          sectionTitle: 'Documentação',
          progressTitle: 'Documentos',
        },
      ],
    }
    const { selected } = formConfig

    let currentTrue = !!(
      this.props.current instanceof Object && this.props.current.id
    )

    let current = this.state.defaultValue

    const { companyInfo, userId } = this.state

    const hideForwardButton = this.state.selected == 4
    const hideBackButton = this.state.selected == 0

    const address = {
      address: current.address,
      additional_address: current.additional_address,
      area: current.area,
      city: current.city,
      state: current.state,
      neighborhood: current.neighborhood,
      number: current.number,
      zip_code: current.zip_code,
    }
    const trading_address = {
      address: current.trading_address_address,
      additional_address: current.trading_address_additional_address,
      city: current.trading_address_city,
      state: current.trading_address_state,
      neighborhood: current.trading_address_neighborhood,
      number: current.trading_address_number,
      zip_code: current.trading_address_zip_code,
    }

    const { typeJuridical } = this.state.defaultValue

    return (
      <BodyContainer isLoading={this.props.isWorking['client']}>
        <Modal
          noTitleMobile
          requestRegistration
          isApp
          isOpen={this.state.modalIsOpen}
          hideForwardButton={hideForwardButton}
          hideBackButton={hideBackButton}
          forward={this.forward.bind(this)}
          back={this.behind.bind(this)}
          close={this.close.bind(this)}
          requestRegistrationClick={this.requestRegistration.bind(this)}
          requestDeleteClick={this.openDeleteModal.bind(this)}
          formConfig={formConfig}
          isLoading={this.state.formIsWorking}
          isInvalid={this.state.formIsInvalid}
          callBack={this.callBack}
          companyInfo={companyInfo}
          activeUser={this.props.activeUser.id}
          activeCompany={this.props.activeCompany.id}
          {...this.props.history}
        >
          {selected === 0 && (
            <>
              <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <ReactToPrint
                  trigger={() => (
                    <Button isBare isMedium>
                      <Icon name='fas fa-print' />
                      Imprimir
                    </Button>
                  )}
                  bodyClass='print-padding'
                  content={() => this.componentRef}
                  copyStyles={true}
                />
              </div>
              <div ref={(element) => (this.componentRef = element)}>
                <h1
                  className='visibleToPrint'
                  style={{ textAlign: 'center', marginBottom: 30, fontSize: 25 }}
                >
                  Dados do(a) cliente
                </h1>
                <Grid>
                  <Grid.Col>
                    <UserName {...current} />
                    <PersonalData {...current} />
                    <DataSpouse {...current} />
                  </Grid.Col>
                  <Grid.Col>
                    {typeJuridical && (
                      <div>
                        <Address
                          {...this.props}
                          address={trading_address}
                          label='Endereço Comercial'
                        />
                        <br />
                        <br />
                        <br />
                      </div>
                    )}
                    <Address
                      {...this.props}
                      address={address}
                      label='Endereço Residencial'
                    />
                  </Grid.Col>
                </Grid>
              </div>
            </>
          )}
          {selected === 1 && (
            <PersonalDataClient
              {...this.state}
              handleChange={this.handleChange.bind(this)}
              showEstimatedRentValue
              isMultiStep={true}
            />
          )}
          {selected === 2 && (
            <div>
              {typeJuridical && (
                <FullTradingAddress
                  {...this.state}
                  handleChange={this.handleChange.bind(this)}
                  isRequired
                />
              )}

              <FullAddress
                {...this.state}
                handleChange={this.handleChange.bind(this)}
                isMultiStep
                isRequired
              />
            </div>
          )}
          {selected === 3 && (
            <PersonalDataSpouse
              {...this.state}
              handleChange={this.handleChange.bind(this)}
              isMultiStep={true}
              isRequired
            />
          )}
          {selected === 4 && currentTrue ? (
            <DocumentComponent
              noMessagebox
              entityName='client'
              entityId={userId}
              isMultiStep={true}
            />
          ) : null}
        </Modal>

        <SmallModal
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.smallModalIsOpen}
          onClose={this.requestRegistrationClose.bind(this)}
        >
          <RequestByEmail
            fullForm
            updateForm
            noMessagebox
            confirmationTrue
            whatsApp
            title='Como você deseja solicitar o cadastro?'
            userId={this.state.userId}
            companyId={this.state.companyId}
            entityName={'client'}
            documents={[]}
          />
        </SmallModal>

        <ConfirmationModal
          disableClose
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.confirmationModal}
          onClose={this.requestRegistrationClose.bind(this)}
        >
          <ConfirmationMessage
            success={this.state.success}
            message={this.state.message}
          />
        </ConfirmationModal>
        {this.state.showExcludeModal && (
          <ConfirmExcludeModal
            title='Excluir Fiador?'
            text='Confirmar exclusão deste fiador?'
            onClose={() =>
              this.setState({
                showExcludeModal: false,
              })
            }
            onExclude={this.requestDelete.bind(this)}
          />
        )}
      </BodyContainer>
    )
  }

  async requestDelete() {
    try {
      const deleteUrl = `/company/${this.props.current.company.id}/client/${this.props.current.id}`

      await request.delete(deleteUrl)

      this.props.notification({
        message: 'Registro excluído com sucesso',
        status: 'success',
      })

      this.props.history.goBack()
    } catch (erro) {
      this.props.notification({
        message: erro.message || 'Erro ao tentar excluir o Cliente',
        status: 'danger',
      })
    }
  }
  openDeleteModal() {
    this.setState({
      showExcludeModal: true,
    })
  }

  requestRegistration() {
    this.setState({
      smallModalIsOpen: true,
    })
  }

  requestRegistrationClose() {
    this.setState({
      smallModalIsOpen: false,
    })
  }

  onAddFile(documents) {
    let length = documents.length + this.state.documentsLength

    this.setState({ documentsLength: length })
  }

  updateFileList(listName, fileList) {
    const documents = this.state.documents

    documents[listName] = fileList
    this.setState({ documents })
  }

  handleUpdateSuccess() {
    this.props.notification({
      message: 'Dados do(a) cliente atualizado com sucesso',
    })
  }

  handlePublicAppUrl(url) {
    copyToClipboard(url)
    this.props.notification({
      message: 'Url copiada para área de transferência',
    })
  }
}

export default connect(
  (store) => ({
    current: store.client.current,
    isWorking: store.commons.isWorking,
  }),
  { ...actions, ...commons },
)(withModal(formCreator(ClientDetails)))
