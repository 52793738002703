import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios'
import { request } from 'core/constants'
import { notification } from 'utils/UtilityStore/actions'
import LoadingContainer from 'components/lib/LoadingContainer'
import { FormFieldSwitch } from 'components/lib/FormFields'
import Dropdown from 'components/lib/Dropdown'
import Button from 'components/lib/Button'
import withRedux from 'components/redux'
import Icon from 'components/lib/Icon'
import { updateUserMeta } from './actions'
import { FilterOnAlert } from './styles'

const ListFilter = ({
  submitForm,
  initialProposalFilters,
  initialAquisitionFilters,
  notification,
  updateUserMeta,
}) => {
  const cancelTokenRef = useRef(null)

  const [proposalFilters, setProposalFilters] = useState(initialProposalFilters)
  const [acquisitionFilters, setAcquisitionFilters] = useState(
    initialAquisitionFilters,
  )
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [filters, setFilters] = useState([])

  const isAcquisitionPage = window.location.pathname.includes('property-capture')

  const fetchFilters = useCallback(async () => {
    try {
      const { data } = await request.get('proposal-status')

      if (isAcquisitionPage) {
        const excludedCodes = [
          'STATUS_HANDOVER_KEYS',
          'STATUS_REFUSED',
          'STATUS_NEW',
        ]

        setFilters(data.filter((item) => !excludedCodes.includes(item.code)))
      } else {
        setFilters(data)
      }
    } catch (err) {
      notification({
        message: 'Não foi possível carregar os filtros',
        status: 'danger',
      })
    } finally {
      setLoadingFilters(false)
    }
  }, [isAcquisitionPage, notification])

  useEffect(() => {
    fetchFilters()
  }, [fetchFilters])

  const handleFilterChange = async (filterCode) => {
    const currentFilters = isAcquisitionPage ? acquisitionFilters : proposalFilters
    const setFiltersFn = isAcquisitionPage
      ? setAcquisitionFilters
      : setProposalFilters

    const updatedFilters = {
      ...currentFilters,
      [filterCode]: !currentFilters[filterCode],
    }

    setFiltersFn(updatedFilters)

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel()
    }

    try {
      cancelTokenRef.current = axios.CancelToken.source()
      const { data } = await request.put(
        'me',
        {
          meta: JSON.stringify(
            isAcquisitionPage
              ? { proposalFilters, acquisitionFilters: updatedFilters }
              : { proposalFilters: updatedFilters, acquisitionFilters },
          ),
        },
        { cancelToken: cancelTokenRef.current.token },
      )

      updateUserMeta(data)
      submitForm()
    } catch (err) {
      notification({
        message: 'Não foi possível alterar os filtros',
        status: 'danger',
      })
    }
  }

  const hasActiveFilters = useCallback(() => {
    const filtersToCheck = isAcquisitionPage ? acquisitionFilters : proposalFilters

    return Object.values(filtersToCheck).some(Boolean)
  }, [acquisitionFilters, proposalFilters, isAcquisitionPage])

  return (
    <Dropdown
      isRight
      keepOpen
      trigger={
        <Button hasThemeColor style={{ padding: '6px 13px 6px 4px' }}>
          <FilterOnAlert style={{ opacity: hasActiveFilters() ? 1 : 0 }} />
          <Icon name='fa fa-filter' />
        </Button>
      }
    >
      <LoadingContainer isVisible={loadingFilters}>
        {filters.map((filter) => (
          <Dropdown.Item key={filter.id}>
            <FormFieldSwitch
              description={filter.name}
              onClick={() => handleFilterChange(filter.code)}
              defaultChecked={Boolean(
                isAcquisitionPage
                  ? acquisitionFilters[filter.code]
                  : proposalFilters[filter.code],
              )}
            />
          </Dropdown.Item>
        ))}
      </LoadingContainer>
    </Dropdown>
  )
}

export default withRedux(
  (state) => ({
    initialProposalFilters: state.app.userMeta?.proposalFilters || {},
    initialAquisitionFilters: state.app.userMeta?.acquisitionFilters || {},
  }),
  { updateUserMeta, notification },
)(ListFilter)
