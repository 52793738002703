import { request } from 'core/constants'
import { requestState } from 'utils/UtilityStore/actions'

export const fetch =
  (routeName, filter, order = null, orderDirection = null) =>
  async (dispatch, getState) => {
    dispatch(requestState(true, 'process'))

    try {
      const { data: filters } = await request.get('proposal-status')

      const userMeta = getState().app?.userMeta || {}
      const activeFilters =
        routeName === 'PropertyCapture'
          ? userMeta.acquisitionFilters
          : userMeta.proposalFilters

      const filtersParams = Object.entries(activeFilters || {})
        .filter(([, value]) => value)
        .map(([filterKey]) => {
          const filter = filters.find((f) => f.code === filterKey)

          return filter ? `filters[status_id][]=${filter.id}` : ''
        })
        .filter(Boolean)
        .join('&')

      const orderBy = order && orderDirection ? `${order},${orderDirection}` : ''

      const filterTypeMap = {
        Rentals: 'filters[type][]=TYPE_RENTAL',
        Sales: 'filters[type][]=TYPE_SALE',
        PropertyCapture: 'filters[type][]=TYPE_ACQUISITION',
      }

      const filterType = filterTypeMap[routeName] || ''

      const url = `{company}/proposalv2?page=${filter?.page || 1}&search=${
        filter?.search || ''
      }&orderBy=${orderBy}&${filterType}&${filtersParams}`

      const { data: payload, resultsInfo: listInfo } = await request.get(url)

      dispatch({
        type: 'FETCH_PROCESS',
        payload,
        listInfo,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching process:', error)
    } finally {
      dispatch(requestState(false, 'process'))
    }
  }
