import React from 'react'

import { fieldProps } from 'features/components/formCreator'

import Header from 'components/lib/Header'
import Grid from 'components/lib/Grid'
import {
  FormFieldSelect,
  FormFieldText,
  FormFieldDate,
} from 'components/lib/FormFields'

import Residencial from './components/Residencial'
import Comercial from './components/Comercial'

import { FormSection } from '../../styles'

export const genderOptions = [
  { name: 'Não definir', value: 'UNDEFINED' },
  { name: 'Feminino', value: 'FEMININO' },
  { name: 'Masculino', value: 'MASCULINO' },
]

export const employmentRelationshipOptions = [
  { name: 'Aposentado/Pensionista', value: 'APOSENTADO/PENSIONISTA' },
  { name: 'Autônomo', value: 'AUTONOMO' },
  { name: 'Empresário', value: 'EMPRESARIO' },
  { name: 'Estudante', value: 'ESTUDANTE' },
  { name: 'Funcionário público', value: 'FUNCIONARIO PUBLICO' },
  { name: 'Funcionário com registro CLT', value: 'FUNCIONARIO CLT' },
  { name: 'Profissional liberal', value: 'LIBERAL' },
  { name: 'Renda proveniente de aluguéis', value: 'RENDA ALUGUEIS' },
]

export const exposedPersonOptions = [
  { name: 'não', value: 'NO' },
  { name: 'sim', value: 'YES' },
  { name: 'proximo', value: 'CLOSE_TO' },
]

const PhysicalPerson = ({ formProps, personData, otherClients, goal }) => {
  const isPoliticallyExposedCloseTo =
    formProps.fields['natural_tenant.main_tenant_politically_exposed_person']
      ?.value === 'CLOSE_TO'

  const partnerComposeRent =
    formProps.fields['natural_tenant.partner_rent_composition']?.value === 'true'

  return (
    <>
      <FormSection>
        <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
          Informações Pessoais
        </Header>

        <Grid>
          <Grid.Col>
            <FormFieldSelect
              label='Gênero *'
              options={genderOptions}
              valueKey='value'
              {...fieldProps(formProps, 'natural_tenant.main_tenant_gender')}
              valueDefault={personData?.main_tenant_gender}
            />
          </Grid.Col>
          <Grid.Col>
            <FormFieldText
              label='Nome da mãe *'
              {...fieldProps(formProps, 'natural_tenant.main_tenant_mother_name')}
              valueDefault={personData?.main_tenant_mother_name}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldSelect
              label='Vínculo empregatício *'
              options={employmentRelationshipOptions}
              valueKey='value'
              {...fieldProps(formProps, 'natural_tenant.employment_relationship')}
            />
          </Grid.Col>

          {(formProps.fields['natural_tenant.employment_relationship']?.value ===
            'FUNCIONARIO PUBLICO' ||
            formProps.fields['natural_tenant.employment_relationship']?.value ===
              'FUNCIONARIO CLT') && (
            <Grid.Col>
              <FormFieldDate
                label='Data de admissão *'
                {...fieldProps(
                  formProps,
                  'natural_tenant.employment_admission_date',
                )}
              />
            </Grid.Col>
          )}
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldSelect
              label='Pessoa publicamente exposta? *'
              valueKey='value'
              options={exposedPersonOptions}
              {...fieldProps(
                formProps,
                'natural_tenant.main_tenant_politically_exposed_person',
              )}
            />
          </Grid.Col>
          {isPoliticallyExposedCloseTo && (
            <>
              <Grid.Col>
                <FormFieldText
                  label='Nome da pessoa *'
                  {...fieldProps(
                    formProps,
                    'natural_tenant.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name',
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <FormFieldText
                  mask={['cpf']}
                  label='CPF da pessoa *'
                  {...fieldProps(
                    formProps,
                    'natural_tenant.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf',
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <FormFieldText
                  label='Grau de parentesco *'
                  {...fieldProps(
                    formProps,
                    'natural_tenant.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person',
                  )}
                />
              </Grid.Col>
            </>
          )}
        </Grid>
      </FormSection>
      {personData?.main_tenant_marital_status === 'CASADO' && (
        <FormSection>
          <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
            Informações do Cônjuge
          </Header>

          <Grid>
            <Grid.Col>
              <FormFieldSelect
                label='Cônjuge compõe renda?'
                valueKey='value'
                options={[
                  { name: 'Não', value: false },
                  { name: 'Sim', value: true },
                ]}
                {...fieldProps(formProps, 'natural_tenant.partner_rent_composition')}
              />
            </Grid.Col>

            {partnerComposeRent && (
              <>
                <Grid.Col>
                  <FormFieldText
                    mask={['cpf']}
                    label='CPF *'
                    {...fieldProps(formProps, 'natural_tenant.partner_cpf')}
                  />
                </Grid.Col>
                <Grid.Col>
                  <FormFieldText
                    label='Nome *'
                    {...fieldProps(formProps, 'natural_tenant.partner_name')}
                  />
                </Grid.Col>
              </>
            )}
          </Grid>
        </FormSection>
      )}
      {goal === 'GOAL_RESIDENTIAL' ? (
        <Residencial formProps={formProps} otherClients={otherClients} />
      ) : (
        <Comercial formProps={formProps} />
      )}
    </>
  )
}

export default PhysicalPerson
